import { alpha, Box, Button, Chip, IconButton, Skeleton, Tab, Tabs, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container'
import { GridColDef, GridRenderCellParams, GridRowParams, GridToolbar } from '@mui/x-data-grid'
import { camelCaseWords, currencyFormatter, s3BucketUrl } from '../../utils/app-helper'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/noDataView'
import { useCashDepositEntries } from '../../hooks/useCashDepositEntries'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { AccountBalanceRounded, CalendarMonthRounded, EventRounded, Sync } from '@mui/icons-material'
import moment from 'moment'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import LoadingView from '../../components/loading-view'
import { useAuth } from '../../hooks/useAuth'



const OutstandingBalance = React.lazy(() => import('../../components/OutstandingBalance'))
const DepositDetails = React.lazy(() => import('../asset-operator/cash-deposit/deposit-details'))



function CashDepositEntries() {

    const theme = useTheme()

    const user = useSelector((state: RootState) => state.userAuth?.currentUser)
    const allDeposits = useSelector((state: RootState) => state?.cashDeposits?.data)

    const { fetchDepositEntries, fetchingDepositEntries } = useCashDepositEntries((user?.role?.name == 'rm' || user?.role?.name == 'rtl' || user?.role?.name == 'cso') ? `${user?.id}` : undefined)
    const { fetchUser, fetchingUser } = useAuth()


    useEffect(() => {
        fetchDepositEntries()
        if (user?.role?.name == 'rm' || user?.role?.name == 'rtl' || user?.role?.name == 'cso')
            fetchUser()
    }, [])


    return (
        <PageContainer>
            <TitleBar title={'Cash Deposit Entries'}
                secondChild={
                    <Box className={'flexCenter'} sx={{ gap: 2, }}>
                        {(user?.provider_id == '1' && (user?.role?.name == 'rm' || user?.role?.name == 'rtl' || user?.role?.name == 'cso')) &&
                            <Suspense fallback={<Skeleton height={36} width={200} />} >
                                <OutstandingBalance />
                            </Suspense>
                        }
                        <IconButton
                            onClick={() => fetchDepositEntries()}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>
                    </Box>
                }
            />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                    '& .entry': {
                        fontSize: '11px',
                        display: 'grid',
                        '& span': {
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            '& svg': {
                                color: theme.palette.text.disabled,
                            }
                        },
                        '&.large': {
                            fontSize: '13px',
                            color: theme.palette.text.primary,
                            '& span': {
                                fontSize: '11px',
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center',
                                gap: '4px',
                                color: theme.palette.text.secondary,
                                '& svg': {
                                    color: theme.palette.text.disabled,
                                },
                            }
                        }
                    }

                }}>
                <CashDepositTable
                    data={allDeposits}
                    fetchingData={fetchingDepositEntries}
                />

            </Box>
        </PageContainer>
    )
}

export default CashDepositEntries


const CashDepositTable = (props: any) => {

    const { data, fetchingData } = props

    const user = useSelector((state: RootState) => state.userAuth?.currentUser)

    const theme = useTheme()

    const [selectedEntry, setSelectedEntry] = useState<any | null>(null)

    const columns: GridColDef[] = [

        {
            field: 'deposite_id', headerName: 'Deposit Id', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => (
                <Typography className={'entry large'}>
                    <strong>{params.row?.deposite_id}</strong>
                    <span><CalendarMonthRounded sx={{ fontSize: '12px' }} /> {moment(params.row?.created_at).format('DD MMM YYYY')}</span>
                </Typography >
            )
        },

        {
            field: 'claimed_amount', headerName: 'Claimed Amount', flex: 1, minWidth: 140,
            renderCell: (params: GridRenderCellParams) => (
                <Typography className={'entry large'} sx={{ color: `${theme.palette.warning.main} !important` }}><strong>{currencyFormatter(+params.row?.claimed_amount)}</strong></Typography>
            )
        },

        {
            field: 'service_order_ids', headerName: 'Services', flex: 1, minWidth: 200,
            renderCell: (params: GridRenderCellParams) => <Tooltip title={params.row?.service_order_ids} arrow placement='top'>
                <Typography className={'entry line-clamp clamp-2'} sx={{ display: '-webkit-box !important' }}>
                    {params.row?.service_order_ids}
                </Typography>
            </Tooltip>
        },

        {
            field: 'bank_name', headerName: 'Deposit Details', flex: 1, minWidth: 180,
            renderCell: (params: GridRenderCellParams) => (
                <Typography className={'entry'} sx={{ color: theme.palette.text.secondary }}>
                    <span><AccountBalanceRounded sx={{ fontSize: '12px' }} /> <strong>{camelCaseWords(params.row?.bank_name)}</strong></span>
                    <span><EventRounded sx={{ fontSize: '12px' }} /> <strong>{moment(params.row?.deposite_date, 'YYYY-MM-DD').format('Do MMM YYYY')}</strong> by <strong>{params.row?.bank_deposite_emp_id ?? ''}</strong></span>
                </Typography>
            )
        },
        {
            field: 'deposite_amount', headerName: 'Deposit Amount', flex: 1, minWidth: 180,
            renderCell: (params: GridRenderCellParams) => (
                <Typography className={'entry large'} sx={{ color: `${theme.palette.info.main} !important` }}><strong>{currencyFormatter(+params.row?.deposite_amount)}</strong></Typography>
            )
        },

        {
            field: 'deposite_reference_no', headerName: 'Reference No', flex: 1, minWidth: 200,
            renderCell: (params: GridRenderCellParams) =>
                <Typography className={'entry large'} sx={{ color: theme.palette.text.secondary }}>
                    <strong>{params.row?.deposite_reference_no}</strong>
                </Typography>
        },

        {
            field: 'deposite_slip', headerName: 'Attachment', flex: 1, minWidth: 100,
            valueFormatter: ({ value }) => value ? `${s3BucketUrl}/${value}` : '-',
            renderCell: (params: GridRenderCellParams) => (
                <Chip label={'view'} size='small' onClick={() => window.open(`${s3BucketUrl}/${params.row.deposite_slip}`, 'bank slip', 'width=800,height=600')} />
            )
        },

        {
            field: 'user_remarks', headerName: 'Remarks', flex: 1, minWidth: 180,
            renderCell: (params: GridRenderCellParams) => (
                <Typography className={'entry'} sx={{ color: theme.palette.text.secondary }}>
                    <Tooltip title={params.row?.user_remarks} arrow><Box component={'span'} className='line-clamp clamp-1' sx={{ display: '-webkit-box !important' }}>User &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - <strong>{params.row?.user_remarks ?? 'na'}</strong></Box></Tooltip>
                    <Tooltip title={params.row?.remarks} arrow><Box component={'span'} className='line-clamp clamp-1' sx={{ display: '-webkit-box !important' }}>Accounts - <strong>{params.row?.remarks ?? 'na'}</strong></Box></Tooltip>
                </Typography>
            )
        },

        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            type: 'actions',
            cellClassName: 'stickyRight',
            headerClassName: 'stickyLeft',
            getActions: (params: GridRowParams<any>) => [
                <Tooltip placement={'top'} title={(user?.role?.name == 'rm' || user?.role?.name == 'rtl') ? 'View' : params.row?.status == '1' ? `Click to take action` : `Can't update`} arrow>
                    <Chip
                        onClick={() => setSelectedEntry(params.row)}
                        size={'small'}
                        label={params.row?.status == '2' ? 'Approved' : params.row?.status == '0' ? 'Rejected' : 'Submitted'}
                        sx={{
                            fontSize: '12px',
                            minWidth: '80px',
                            boxShadow: `0 0 12px -4px ${params.row?.status == '2' ? theme.palette.success.main : params.row?.status == '0' ? theme.palette.error.main : theme.palette.info.main} inset`,
                            background: theme.palette.background.paper,
                            color: params.row?.status == '2' ? theme.palette.success.main : params.row?.status == '0' ? theme.palette.error.main : theme.palette.info.main,
                            '&:hover': {
                                background: params.row?.status == '2' ? theme.palette.success.main : params.row?.status == '0' ? theme.palette.error.main : theme.palette.info.main,
                                color: params.row?.status == '2' ? theme.palette.success.contrastText : params.row?.status == '0' ? theme.palette.error.contrastText : theme.palette.info.contrastText,
                            },
                        }}
                    />
                </Tooltip>,
            ],
        },

        { field: 'created_at', headerName: 'Create Date' },
        { field: 'deposite_date', headerName: 'Deposite Date' },
        { field: 'bank_deposite_emp_id', headerName: 'Deposite By' },
        { field: 'remarks', headerName: 'Accounts Remarks' },
        {
            field: 'status', headerName: 'Status',
            valueFormatter: ({ value }) => value ? (value == '2' ? 'APPROVED' : (value == '0' ? 'REJECTED' : 'SUBMITTED')) : '-'
        },
    ]

    const smallScreen = useMediaQuery('(max-width:900px)')

    return (
        <>
            <ThemeDataTable
                loading={fetchingData}
                disableRowSelectionOnClick
                disableColumnMenu
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                pageSizeOptions={[20, 50, 100]}
                density={'standard'}
                rows={data?.length > 0 ? data : []}
                columns={columns}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: TableLoading,
                    noRowsOverlay: NoDataView,
                }}

                columnVisibilityModel={{
                    created_at: false,
                    deposite_date: false,
                    bank_deposite_emp_id: false,
                    remarks: false,
                    status: false,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                        csvOptions: {
                            disableToolbarButton: !(user?.role?.name == 'super admin' || user?.role?.name == 'accounts' || user?.role?.name == 'mis' || user?.role?.name == 'admin'),
                            allColumns: true
                        },
                        printOptions: { disableToolbarButton: true },
                    },
                }}
                initialState={{
                    filter: {
                        filterModel: {
                            items: [],
                            quickFilterExcludeHiddenColumns: true,
                        },
                    },
                }}
            />


            {!!selectedEntry &&
                <ThemeDialog
                    open={!!selectedEntry}
                    title={'Verify Cash Deposit'}
                    size={smallScreen ? 'large' : 'small'}
                    onClickClose={() => (selectedEntry?.status != '1' || (user?.role?.name == 'rm' || user?.role?.name == 'rtl')) && setSelectedEntry(null)}
                    dialogBody={
                        <Suspense fallback={<LoadingView />}>
                            <DepositDetails detail={selectedEntry} close={() => setSelectedEntry(null)} />
                        </Suspense>
                    }
                />
            }
        </>

    )
}

