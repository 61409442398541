import React, { Suspense, useEffect, useState } from 'react'
import ThemeColorToggle from '../../../components/theme-color-toggle'
import { Avatar, Box, Button, ButtonBase, Divider, Skeleton, Typography, alpha, useTheme } from '@mui/material'
import useLogout from '../../../hooks/useLogout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { camelCaseWords, s3BucketUrl } from '../../../utils/app-helper'
import { ArrowRight, MailRounded } from '@mui/icons-material'
import FileUploadInput from '../../../components/inputs/fileUploadInput'
import { ThemeTextField } from '../../../components/inputs/theme-text-field'
import { Controller, useForm } from 'react-hook-form'
import API from '../../../api'
import toast from 'react-hot-toast'
import { updateProcessState } from '../../../slices/process-slice'
import { updateAuth } from '../../../slices/auth-slice'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'
import LoadingView from '../../../components/loading-view'
import MyAssociates from '../../my-associates'
import { MailIcon, SupervisorIcon, TagIcon } from '../../../components/icons'
import { DetailItem } from '../../my-account'

const MyAttendance = React.lazy(() => import('../../../components/MyAttenance'))
const OutstandingBalance = React.lazy(() => import('../../../components/OutstandingBalance'))


function OperatorAccount() {

    const dispatch = useDispatch()
    const logout = useLogout()
    const theme = useTheme()
    const currentUser = useSelector((state: RootState) => state?.userAuth?.currentUser)

    const [showForm, setShowForm] = useState(false)


    const { handleSubmit, control, register, reset, watch, setValue, formState: { errors } } = useForm()

    const inputData = watch()

    const onSubmit = (data: any) => {
        console.log(data)
        const { old_password, confirm_text_password, user_profile_picture, ...restData } = data
        if (data?.old_password && data?.old_password != currentUser?.text_password) {
            toast.error('Invalid old password.')
            return
        }
        if (data?.text_password == '' && data?.user_profile_picture?.length < 1) {
            setShowForm(false)
            return
        }

        const newData = { ...restData, user_profile_picture: data?.user_profile_picture[0], id: `${currentUser?.id}` }
        dispatch(updateProcessState(true))
        API.post('/update_operator_profile', newData, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
            .then((res) => {
                if (res.data.status == 'success') {
                    toast.success('Updated successfully!')
                    dispatch(updateAuth({
                        isLoggedIn: true,
                        currentUser: {
                            ...currentUser,
                            text_password: res.data?.data?.text_password,
                            profile_image: res.data?.data?.profile_image,
                        }
                    }))
                    setShowForm(false)
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                dispatch(updateProcessState(false))
            })
    }

    return (
        <>
            <Box
                sx={{
                    minHeight: '75vh',
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 3,
                    maxWidth: '550px',
                    mx: 'auto',
                }}>
                <Box
                    sx={{
                        borderRadius: '32px',
                        position: 'relative',
                        display: 'flex',
                        flexFlow: 'column',
                        justifyContent: 'flex-end',
                        boxShadow: `0 0 9px -3px ${alpha(theme.palette.primary.main, 0.2)}, 0 0 69px -3px ${alpha(theme.palette.primary.main, 0.2)} inset`,
                        p: 2,
                        pt: 10,
                        border: `3px solid ${theme.palette.background.default}`,
                        '& .MuiAvatar-root': {
                            height: '70px',
                            width: '70px',
                            outline: `2px solid ${theme.palette.background.default}`,
                            borderRadius: '16px',
                        },
                    }}>
                    <Box sx={{
                        position: 'absolute',
                        top: '16px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '90%',
                        gap: 2,
                        '& .link': {
                            userSelect: 'none',
                            fontSize: '12px',
                            color: theme.palette.primary.main,
                            fontFamily: theme.typography.body1.fontFamily,
                            width: 'max-content',
                        },
                    }}>
                        <ButtonBase className={'link'} onClick={() => {
                            setShowForm(prev => !prev)
                            reset()
                        }}>{showForm ? 'Discard Update' : 'Edit Profile'}</ButtonBase>

                        <ThemeColorToggle size={'small'} />
                    </Box>

                    {!showForm
                        ? <Box className={'animate__animated animate__fadeIn'}
                            sx={{
                                background: theme.palette.background.default,
                                borderRadius: '24px',
                                p: 1,
                                display: 'flex',
                                flexWrap: 'wrap',
                                alignItems: 'flex-start',
                                gap: 2,
                                alignSelf: 'flex-start',
                                width: '100%',
                            }}>

                            <Avatar
                                sx={{
                                    '& .MuiAvatar-img': {
                                        objectFit: 'contain'
                                    }
                                }}
                                alt={currentUser?.name ?? ''}
                                src={currentUser?.profile_image ? `${s3BucketUrl}/operator_profile_pic/${currentUser?.profile_image}` : ''} />
                            <Box sx={{
                                flex: '1 1 180px',
                                alignSelf: 'stretch',
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'flex-end',
                                '& .name': {
                                    fontSize: 'clamp(1rem, 6vw, 1.2rem)',
                                    fontFamily: theme.typography.h1.fontFamily,
                                    fontWeight: 500,
                                },
                                '& .role': {
                                    fontSize: '10px',
                                    background: theme.palette.action.focus,
                                    // outline: `1px solid ${theme.palette.primary.light}`,
                                    maxWidth: 'max-content',
                                    borderRadius: '12px',
                                    p: '0 6px',
                                },
                            }}>
                                <Typography className='role line-clamp clamp-1'>{camelCaseWords((currentUser?.is_supervisor_head == '1' && currentUser?.role?.name == 'operator') ? 'Captain' : (currentUser?.role?.name ?? ''))}</Typography>
                                <Typography className='name line-clamp clamp-1'>{camelCaseWords(currentUser?.name ?? '')}</Typography>
                            </Box>

                            <Divider sx={{ minWidth: '100%' }} />

                            <Box sx={{
                                width: '100%',
                                '& .username': {
                                    fontSize: '14px',
                                    color: theme.palette.text.primary,
                                    display: 'flex',
                                    alignItems: 'center',
                                    alignSelf: 'flex-start',
                                    gap: 1,
                                    ml: 1,
                                    mb: 2,
                                    '& svg': {
                                        height: '24px',
                                        width: '24px',
                                        color: theme.palette.text.disabled,
                                        // display: 'none',
                                    },
                                },
                            }}>
                                <Typography className={'username'}><TagIcon /><DetailItem content={currentUser?.emp_id ?? ''} label='Employee Code' /></Typography>
                                <Typography className={'username'}><MailIcon /><DetailItem content={currentUser?.email ?? ''} label='Email' /></Typography>
                                <Typography className={'username'}><SupervisorIcon /><DetailItem content={currentUser?.supervisor_name ?? ''} label='Supervisor' /></Typography>

                            </Box>
                        </Box>
                        : <Box className={'animate__animated animate__fadeIn'}
                            sx={{
                                mt: 1,
                            }}>
                            <FileUploadInput
                                label={'Avatar'}
                                cameraOnly={false}
                                aspectRatio={'2/1'}
                                accept={'image/png, image/gif, image/jpeg'}
                                reactHookProps={{ ...register('user_profile_picture') }}
                                onClear={() => setValue('user_profile_picture', null)}
                                errorMessage={errors.user_profile_picture?.message?.toString() ?? ''}
                            />
                            <br />
                            <Controller name={`old_password`}
                                control={control}
                                rules={{
                                    required: { value: inputData?.text_password ? true : false, message: 'Required' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required className='flex1'
                                        error={Boolean(errors?.old_password)}
                                        helperText={(errors?.old_password?.message ?? '').toString()}
                                        size={'small'} label={'Old Password'}
                                        placeholder={'XXXXXXXXX'}
                                    />
                                )} />

                            <Controller name={`text_password`}
                                control={control}
                                rules={{
                                    required: { value: inputData?.old_password ? true : false, message: 'Required' },
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required
                                        error={Boolean(errors?.text_password)}
                                        helperText={(errors?.text_password?.message ?? '').toString()}
                                        onBlur={(e: any) => setValue('text_password', `${e.target.value?.trim()}`)}
                                        size={'small'} label={'New Password'}
                                        placeholder={'XXXXXXXXX'}
                                    />
                                )} />

                            <Controller name={`confirm_text_password`}
                                control={control}
                                rules={{
                                    required: { value: inputData?.text_password ? true : false, message: 'Required' },
                                    validate: (value) => inputData?.text_password ? value == inputData?.text_password : true
                                }}
                                defaultValue={''}
                                render={({ field }) => (
                                    <ThemeTextField
                                        {...field} required
                                        error={Boolean(errors?.confirm_text_password)}
                                        helperText={(errors?.confirm_text_password?.message ?? '').toString()}
                                        size={'small'} label={'Confirm Password'}
                                        onBlur={(e: any) => setValue('confirm_text_password', `${e.target.value?.trim()}`)}
                                        placeholder={'XXXXXXXXX'}
                                    />
                                )} />

                            <Button
                                fullWidth
                                variant='contained'
                                onClick={handleSubmit(onSubmit)}
                                disabled={
                                    (inputData?.text_password == '' && inputData?.user_profile_picture?.length < 1) ?? true
                                }>
                                Update
                            </Button>
                        </Box>
                    }

                </Box>

                {!showForm &&
                    <>
                        <Box className={'animate__animated animate__fadeIn'}
                            sx={{
                                flex: 1,
                                display: 'flex',
                                flexFlow: 'column',
                                justifyContent: 'flex-end',
                                gap: 3,
                                '& .block': {
                                    flex: 1,
                                    '& .link': {
                                        maxWidth: 'max-content',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 0.3,
                                        ml: 3,
                                        color: theme.palette.info.main,
                                        transition: 'all 200ms ease-in-out',
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                        '& svg': {
                                            opacity: 0,
                                            transition: 'all 200ms ease-in-out',
                                        },
                                        '&:hover': {
                                            color: theme.palette.info.dark,
                                            gap: 0.8,
                                            '& svg': {
                                                opacity: 1,
                                            },
                                        },
                                    },
                                }
                            }}>

                            {currentUser?.provider_id == '1' &&
                                <Suspense fallback={<Skeleton width={'100%'} />}>
                                    <OutstandingBalance fullwidth />
                                </Suspense>
                            }

                            <Box className={'block'}>
                                <FormBlockTitle title='My Attendance' icon={false} />
                                <Suspense fallback={<LoadingView />}>
                                    <MyAttendance />
                                </Suspense>
                            </Box>


                            <Box className={'block'}>
                                <FormBlockTitle title='Useful Links' icon={false} />
                                <Typography component={'a'} href={'https://trainingbeta.s3.ap-south-1.amazonaws.com/Software+Handbook.pdf'} target='_blank' download className={'link'}>👉 Software Handbook<ArrowRight /></Typography>
                                {currentUser?.provider_id == '1' &&
                                    <>
                                        <Typography component={'a'} href='https://trainingbeta.s3.ap-south-1.amazonaws.com/Employee+Handbook.pdf' target='_blank' download className={'link'}>👉 Employee Handbook<ArrowRight /></Typography>
                                        <Typography component={'a'} href='https://trainingbeta.s3.ap-south-1.amazonaws.com/23.1.+Spray+Services+Operation+Control+Procedure.pdf' target='_blank' download className={'link'}>👉 Spray Service Operation Control Procedure<ArrowRight /></Typography>
                                    </>
                                }
                            </Box>

                            <Button size='large' variant='outlined' color='error' fullWidth onClick={logout}>Logout</Button>
                        </Box>
                    </>
                }

            </Box >

        </>
    )
}

export default OperatorAccount