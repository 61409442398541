import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import API from "../api"
import { updateCashDeposits } from "../slices/cash-deposits-slice"
import toast from "react-hot-toast"

export function useCashDepositEntries(user_id?: string) {

  const [depositEntries, setDepositEntries] = useState<any[]>([])
  const [fetchingDepositEntries, setFetchingDepositEntries] = useState(false)

  const dispatch = useDispatch()

  const fetchDepositEntries = useCallback(async () => {
    try {
      setFetchingDepositEntries(true)
      const response = await API.post("/cash-deposite-list", { user_id: user_id ?? '' })

      if (response.data?.status === "success") {
        const reversedData = response.data?.data?.reverse() || []
        setDepositEntries(reversedData)
        dispatch(updateCashDeposits({ data: reversedData }))
      } else {
        toast.error(`Error\nFailed to fetch Deposit Entries`)
        console.error(
          "Failed to fetch depositEntries:",
          response.data?.message || "Unknown error"
        )
      }
    } catch (error) {
      console.error("Error fetching depositEntries:", error)
    } finally {
      setFetchingDepositEntries(false)
    }
  }, [user_id, dispatch])

  return {
    fetchDepositEntries,
    depositEntries,
    fetchingDepositEntries,
  }
}
