import moment, { MomentFormatSpecification, MomentInput } from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { User } from "../models/user";
import toast from "react-hot-toast";

export const capitalizeWords = (str: string = "", lower = false) => {
  return (lower ? str.toLowerCase() : str).replace(
    /(?:^|\s|["'([{])+\S/g,
    (match) => match.toUpperCase()
  );
};

export const camelCaseWords = (inputString: string) => {
  return inputString
    ?.toLowerCase()
    ?.replace(/\b[a-z]/g, (letter) => letter.toUpperCase());
};

export const titleCaseWords = (inputString: string) => {
  return inputString
    ?.replace(/_/g, " ")
    ?.replace(/\b\w/g, (letter) => letter.toUpperCase());
};

export const currencyFormatter = (value: number) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(value);
};

export const utcMoment = (
  input?: MomentInput,
  format?: MomentFormatSpecification
) => moment.utc(input, format);

export const getFirstErrorMessage = (response: any) => {
  const errors = response;
  const firstKey = Object.keys(errors)?.[0];
  return errors?.[firstKey]?.[0];
};

export const pluralSuffix = (value: number) => (value > 1 ? "s" : "");

export const downloadFile = (data: any, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link); // Clean up and remove the link
};

export const getCookieValue = (cookieName: string) => {
  const cookies = document.cookie.split("; ");
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === cookieName) {
      return decodeURIComponent(value);
    }
  }
  return null; // Cookie not found
};

export const preventClose = () => {
  const handleBeforeUnload = (e: any) => {
    e.preventDefault();
  };
  window.addEventListener("beforeunload", handleBeforeUnload);
  return () => {
    window.removeEventListener("beforeunload", handleBeforeUnload);
  };
};

export const scrollToTop = () => {
  const scrollContainer = document.getElementById("scrollContainer");
  if (scrollContainer) {
    scrollContainer.scrollTop = 0;
  }
};


export const generateCSV = (data: any[], fileName = 'data.csv') => {
  if (!data || !data.length) {
    alert('No data available to export');
    return;
  }
  const headers = Object.keys(data[0]);
  const csvContent = [
    headers.join(','),
    ...data.map(row =>
      headers.map(header =>
        `"${row[header] || ''}"`
      ).join(',')
    )
  ].join('\n')

  const blob = new Blob([csvContent], { type: 'text/csv' });
  const a = document.createElement('a');
  a.href = URL.createObjectURL(blob);
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}


export function getTimeDifference(time1: string, time2: string) {
  const diff = moment(time2, "HH:mm:ss").diff(moment(time1, "HH:mm:ss"), 'minutes')
  const hours = Math.floor(diff / 60)
  const minutes = diff % 60
  return `${hours}:${minutes}:00`
}

export const getAllDatesUpToToday = (month: string, tillToday?: boolean): string[] => {
  const startOfMonth = moment(month, 'YYYY-MM-DD').startOf('month')
  const endOfMonth = moment(month, 'YYYY-MM-DD').endOf('month')
  const today = moment()
  const endDay = tillToday ? moment.min(today, endOfMonth) : endOfMonth
  let dates: string[] = []
  let currentDay = startOfMonth
  while (currentDay.isSameOrBefore(endDay)) {
    dates.push(currentDay.format('DD-MM-YYYY'))
    currentDay.add(1, 'day')
  }
  return dates
}

export const getRoleName = (role: string) => {
  const rolename = role == 'super admin' ? 'Super Admin'
    : role == 'admin' ? 'Admin'
      : role == 'rm' ? 'Regional Manager'
        : role == 'rtl' ? 'Regional Territory Lead'
          : role == 'hr' ? 'Human Resource'
            : role == 'accounts' ? 'Accounts'
              : role == 'client' ? 'Clients'
                : role == 'management' ? 'Management'
                  : role == 'mis' ? 'MIS Executive'
                    : role == 'cso' ? 'CSO'
                      : role == 'operator' ? 'Operator'
                        : role == 'dge' ? 'DGE'
                          : 'Unverified User'
  return rolename
}


export const getIndianTime = (value: string) => {
  return moment(value).utcOffset(330)
}

export const terCategories = [
  { id: "fuel", name: "Fuel" },
  { id: "maintenance", name: "Maintenance" },
  { id: "lodgingAndBoarding", name: "Lodging & Boarding" },
  { id: "meal", name: "Meal" },
  { id: "mobile", name: "Mobile" },
  { id: "publicTransport", name: "Public Transport" },
  { id: "electricity", name: "Electricity" },
  { id: "dailyAllowance", name: "Daily Allowance" },
  { id: "otherExpense", name: "Other Expense" },
];

export const activityRemarks = [
  "Service Request fulfillment",
  "Farmer Meeting",
  "Retailer Meeting",
  "Marketing",
  "Hub Transition",
  "Business Meeting",
  "Imparting Training",
  "No Activity due to weather Conditions",
  "Training",
  "Other",
];

export const serverRouteWithoutApi =
  window.location.hostname == "training.agriwings.app"
    ? "https://training-api.d2f.co.in"
    : window.location.hostname == "www.agriwings.app" ||
      window.location.hostname == "agriwings.app"
      ? "https://api.d2f.co.in"
      : "https://development-api.d2f.co.in";
// : "http://192.168.8.131:8000";

export const serverRoute =
  window.location.hostname == "training.agriwings.app"
    ? "https://training-api.d2f.co.in/api"
    : window.location.hostname == "www.agriwings.app" ||
      window.location.hostname == "agriwings.app"
      ? "https://api.d2f.co.in/api"
      : "https://development-api.d2f.co.in/api";
// : "http://192.168.8.131:8000/api";

export const s3BucketUrl =
  window.location.hostname == "www.agriwings.app" ||
    window.location.hostname == "agriwings.app"
    ? "https://agriwing.s3.ap-south-1.amazonaws.com"
    : "https://trainingbeta.s3.ap-south-1.amazonaws.com";
