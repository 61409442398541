import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { CashDeposit } from "../models/cashDeposit";

export interface CashDepositsInterface {
  data: CashDeposit[]
}

const initialState: CashDepositsInterface = {
  data: []
};

export const cashDepositsSlice = createSlice({
  name: "cashDeposits",
  initialState,
  reducers: {
    updateCashDeposits: (state, action: PayloadAction<CashDepositsInterface>) => {
      state.data = action.payload?.data;
    },
  },
});

export const { updateCashDeposits } = cashDepositsSlice.actions;

export default cashDepositsSlice.reducer