import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, getIndianTime, s3BucketUrl } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';
import { AttachmentIcon } from '../../components/icons';
import moment, { Moment } from 'moment';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { themeTextFieldSx } from '../../components/inputs/theme-text-field';


interface FilterOptionInterface {
    from: Moment | null,
    to: Moment | null,
}


export default function MeetingReport() {

    useDocumentTitle('Meetings')

    const theme = useTheme()
    const [meetings, setMeetings] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const [filterOptions, setFilterOptions] = useState<FilterOptionInterface>({
        from: moment().subtract(1, 'week'),
        to: moment(),
    })

    const fetchMeetings = useCallback(() => {
        setFetching(true)
        API.get(`/meeting-report`, {
            params: {
                from_date: filterOptions.from ? moment(filterOptions.from).format('YYYY-MM-DD') : '',
                to_date: filterOptions.to ? moment(filterOptions.to).format('YYYY-MM-DD') : '',
            }
        })
            .then((res) => {
                setMeetings(res.data?.data?.map((item: any, i: number) => ({
                    ...item,
                    id: i + 1
                })))
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [filterOptions])

    useEffect(() => {
        fetchMeetings()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Meetings Report'} secondChild={
                <Box
                    className={'flexBottomCenter'}
                    sx={{
                        flex: '1 0 450px',
                        flexWrap: 'wrap',
                        gap: 1,
                        '& .formInput': {
                            minWidth: '110px',
                            maxWidth: '140px'
                        },
                    }}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                            disableFuture
                            label="From"
                            format='DD-MM-YYYY'
                            className='formInput'
                            value={filterOptions.from}
                            closeOnSelect
                            // onClose={(date: Moment) => setFilterOptions({ ...filterOptions, from: date })}
                            onChange={(newValue) => setFilterOptions({ ...filterOptions, from: newValue })}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    required: true,
                                    sx: themeTextFieldSx,
                                }
                            }}
                        />
                        <MobileDatePicker
                            disableFuture
                            label="To"
                            format='DD-MM-YYYY'
                            className='formInput'
                            closeOnSelect
                            value={filterOptions.to}
                            onChange={(newValue) => setFilterOptions({ ...filterOptions, to: newValue })}
                            slotProps={{
                                textField: {
                                    size: 'small',
                                    required: true,
                                    sx: themeTextFieldSx,
                                }
                            }}
                        />
                    </LocalizationProvider>
                    <IconButton
                        onClick={fetchMeetings}
                        size='small'
                        sx={{
                            mr: 2,
                            background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                            boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `1px solid ${theme.palette.background.paper}`,
                        }}>
                        <Sync />
                    </IconButton>
                </Box>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .iconButton': {
                        height: '24px',
                        width: '24px',
                        borderRadius: '50vh',
                        // boxShadow: `0 0 12px -4px ${theme.palette.info.main} inset`,
                        display: 'grid',
                        placeItems: 'center',
                        color: theme.palette.info.main,
                        '& svg': {
                            height: '16px',
                            width: '16px',

                        },

                    },
                }}>

                <MeetingsTable data={meetings} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const MeetingsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Sr', flex: 1, },
        {
            field: 'meeting_id', headerName: 'Meeting ID', flex: 1, minWidth: 130,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        { field: 'meeting_date', headerName: 'Meeting Date', flex: 1, minWidth: 160 },

        {
            field: 'meeting_type', headerName: 'Meeting Type', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },

        {
            field: 'images', headerName: 'Images', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => JSON.parse(value).map((el: string) => (
                `${s3BucketUrl}/${el}`
            )).join(', '),
            renderCell: (params: GridRenderCellParams) =>
                <span className={'flexCenter'}>
                    {
                        JSON.parse(params.value).map((el: string, i: number) => (
                            <a className={'iconButton'} href={`${s3BucketUrl}/${el}`} target="_blank" key={i} style={{ marginRight: '8px' }}>
                                <AttachmentIcon />
                            </a>
                        ))
                    }
                </span>
        },
        // { field: 'village', headerName: 'Village', flex: 1, minWidth: 160 },
        { field: 'district', headerName: 'District', flex: 1, minWidth: 160 },
        { field: 'sub_district', headerName: 'Subdistrict', flex: 1, minWidth: 160 },
        { field: 'state', headerName: 'State', flex: 1, minWidth: 160 },
        { field: 'pin', headerName: 'Pin Code', flex: 1, minWidth: 100 },
        {
            field: 'farmer_id', headerName: 'Farmer Id', flex: 1, minWidth: 120,
        },
        {
            field: 'farmer_code', headerName: 'Farmer Code', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'farmer_name', headerName: 'Farmer Name', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'farmer_mobile_no', headerName: 'Farmer Mobile', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value : '-'
        },
        {
            field: 'farmer_crop', headerName: 'Farmer Crops', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        { field: 'user_id', headerName: 'User Id', flex: 1, minWidth: 100 },
        {
            field: 'user_name', headerName: 'User Name', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? capitalizeWords(value) : '-'
        },
        {
            field: 'user_role', headerName: 'User Role', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? capitalizeWords(value) : '-'
        },
        {
            field: 'created_at', headerName: 'Created On', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? getIndianTime(value).format("YYYY-MM-DD HH:mm:ss") : '-'

        },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


