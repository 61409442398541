import React, { useCallback, useEffect, useState } from 'react'
import API from '../../api'
import { useDispatch } from 'react-redux'
import { updateProcessState } from '../../slices/process-slice'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { Box, Chip, IconButton, Typography, alpha, useMediaQuery, useTheme } from '@mui/material'
import { Sync } from '@mui/icons-material'
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid'
import { ThemeDataTable } from '../../components/theme-data-table-container'
import TableLoading from '../../components/theme-table-container/TableLoading'
import NoDataView from '../../components/noDataView'
import { currencyFormatter } from '../../utils/app-helper'
import { useDocumentTitle } from '../../hooks/useDocumnetTitle'

function Transactions() {

    useDocumentTitle('Transactions')

    const theme = useTheme()
    const distach = useDispatch()

    const [fetching, setFetching] = useState(true)
    const [transactions, setTransactions] = useState<any[]>([])

    const fetchTransactions = useCallback(() => {
        distach(updateProcessState(true))
        API.get('/get_services_transactions')
            .then((res) => {
                console.log(res.data?.data)
                setTransactions(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetching(false)
                distach(updateProcessState(false))
            })
    }, [])

    useEffect(() => {
        fetchTransactions()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Transactions Report'} secondChild={
                <IconButton
                    onClick={fetchTransactions}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <TransactionsTable data={transactions} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const TransactionsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'UIN', flex: 1, minWidth: 60 },
        {
            field: 'amount', headerName: 'Amount', flex: 1, minWidth: 120,
            renderCell: (params: GridRenderCellParams) =>
                <Typography sx={{ fontSize: '13px', fontWeight: 600, color: theme.palette.warning.main }}>
                    {currencyFormatter(params.value)}
                </Typography>
        },
        {
            field: 'order_status', headerName: 'Status', flex: 1, minWidth: 100,
            renderCell: (params: GridRenderCellParams) =>
                <Chip
                    size={'small'}
                    variant='outlined'
                    label={params.value}
                    color={params.value == 'Success' ? 'success' : (params.value == 'Failure' ? 'error' : 'warning')}
                    sx={{
                        fontSize: '12px',
                        lineHeight: '12px',
                        p: '2px',
                        height: '18px',
                        minWidth: '60px',
                        textTransform: 'capitalize',
                        boxShadow: `0 0 12px -4px currentcolor inset`,
                    }} />
        },
        { field: 'service_id', headerName: 'Service Id', flex: 1, minWidth: 100 },
        { field: 'bank_ref_no', headerName: 'Reference No', flex: 1, minWidth: 120 },
        { field: 'tracking_id', headerName: 'Tracking Id', flex: 1, minWidth: 160 },
        { field: 'created_at', headerName: 'Created At', flex: 1, minWidth: 160 },
        { field: 'payment_date_time', headerName: 'Payment Time', flex: 1, minWidth: 160 },
        { field: 'payment_mode', headerName: 'Mode', flex: 1, minWidth: 160 },
    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            columnVisibilityModel={{
            }}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}

export default Transactions
