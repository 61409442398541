import React, { useState } from 'react'
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText, useMediaQuery } from "@mui/material";
import {
    AcUnitRounded,
    AccountBalanceRounded,
    AccountTreeRounded,
    AssessmentRounded,
    AssignmentRounded,
    AssuredWorkloadRounded,
    AutoAwesomeRounded, BatteryFullRounded, BusinessRounded, ContactMailRounded,
    ContactsRounded,
    DashboardRounded,
    DescriptionRounded,
    DiscountRounded,
    Diversity1Rounded,
    Diversity2Rounded,
    Diversity3Rounded,
    ExpandLessRounded,
    ExpandMoreRounded,
    FolderSharedRounded,
    GrassRounded,
    Groups3Rounded,
    GroupsRounded,
    HiveRounded,
    ImportExportRounded,
    InsightsRounded, KeyboardCommandKeyRounded, MapRounded, PaymentsRounded, PeopleRounded, PermContactCalendarRounded, PortraitRounded, ReceiptLongRounded, RecordVoiceOverRounded, SupervisorAccountRounded, SupportRounded, TwoWheelerRounded,
    WorkspacesRounded
} from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { User } from "../../models/user";
import { DroneIcon } from '../../components/icons';


const NavItem = (props: any) => {

    const { onExpandMenu, open, isDrawerOpen } = props

    return (
        <ListItem disablePadding onClick={onExpandMenu} className={props.subMenus && open ? `navItem open` : `navItem`} >
            <ListItemButton>
                <ListItemIcon>
                    {(props.subMenus && isDrawerOpen)
                        && (open ? <ExpandLessRounded className={'submenuArrow expandArrow'} /> :
                            < ExpandMoreRounded className={'submenuArrow expandArrow'} />)
                    }
                    <props.icon />
                </ListItemIcon>
                < ListItemText primary={props.label} />
            </ListItemButton>
        </ListItem>
    )
}

interface NavigationItemProps {
    label: string
    link: string
    icon: any
    subMenus?: NavigationItemProps[]
}

const NavigationItems = (props: any) => {

    const navItems: NavigationItemProps[] = props.navItems
    const toggleDrawer = props.toggleDrawer
    const isDrawerOpen = props.isDrawerOpen
    const componentType = props.componentType ?? 'div'
    const isSmallScreen = useMediaQuery('(max-width:900px)')

    const [clickIndex, setClickIndex] = useState<number | undefined>(undefined)

    return (
        <List component={componentType} >
            {
                navItems.map((navItem, index) => {
                    return (
                        navItem.subMenus && navItem.subMenus.length > 0
                            ? <React.Fragment key={index} >
                                <NavItem icon={navItem.icon}
                                    label={navItem.label}
                                    onExpandMenu={() => index == clickIndex ? setClickIndex(undefined) : setClickIndex(index)}
                                    open={index == clickIndex}
                                    subMenus isDrawerOpen={isDrawerOpen}
                                />
                                <Collapse in={index == clickIndex} timeout="auto" unmountOnExit >
                                    <NavigationItems navItems={navItem.subMenus} toggleDrawer={toggleDrawer} />
                                </Collapse>
                            </React.Fragment>
                            : <NavLink key={index} to={navItem.link}
                                className={({ isActive }) => isActive ? 'activeMenu' : ''}
                                onClick={
                                    isSmallScreen ? toggleDrawer : () => {
                                    }
                                } >
                                <NavItem icon={navItem.icon} label={navItem.label} isDrawerOpen={isDrawerOpen} />
                            </NavLink>
                    )
                })}
        </List>
    )
}


interface NavigationListProps {
    open: boolean
    hovered: boolean
    toggleDrawer: () => void
}

const NavigationList = (props: NavigationListProps) => {

    const { open, toggleDrawer, hovered } = props


    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const isD2f = currentUser?.provider_id == '1'

    const role = currentUser?.role?.name
    const roleNames = ['super admin', 'cso', 'admin', 'rm', 'rtl', 'accounts', 'hr', 'client', 'mis', 'management']
    const roles = Object.fromEntries(roleNames.map(roleName => [roleName.replace(' ', ''), role === roleName]))
    const { superadmin, admin, rm, cso, rtl, client, accounts, hr, management, mis } = roles
    const isAdmin = superadmin || admin

    const reports = {
        label: 'Reports', link: '/reports', icon: AssessmentRounded,
        subMenus: [...providersLink.reports, ...(isD2f ? d2fLinks.reports : [])]
    }

    const fleetManagment = {
        label: 'Fleet Management', link: '/fleet-management', icon: HiveRounded,
        subMenus: [...providersLink.fleet, ...(isD2f ? d2fLinks.fleet : [])]
    }



    const menuList = [
        dashboard,
        ...(!isD2f
            ? [service, fleetManagment, attendance, reports]
            : [
                ...(!(management || mis)
                    ? [...(!(hr || accounts)
                        ? [
                            ...(isAdmin ? [users, providers] : []),
                            ...((cso || client) ? [createFarmers] : [farmers]),
                            service,
                            ...(isAdmin || rm || rtl
                                ? [clusters, crops, schemes, clients, fleetManagment, reports,]
                                : []
                            ),
                            ...((isAdmin) ? [transactions] : []),
                            ...((isAdmin || rm || rtl || hr || currentUser?.is_supervisor_head == '1') ? [attendance] : []),
                            ...(rtl ? [meetings] : []),
                            ...((isAdmin || rm || rtl || cso) ? [cashDepositEntries] : []),

                        ]
                        : [
                            serviceReport,
                            transactions,
                            ...(hr ? [attendance, hrReport,] : []),
                            ...(accounts ? [cashDepositEntries] : []),
                        ]
                    )]
                    : [
                        clusters,
                        reports,
                        ...(mis ? [transactions, cashDepositEntries] : []),
                    ]
                ),
            ]),
    ]

    return (
        <NavigationItems
            componentType={'nav'}
            navItems={menuList}
            isDrawerOpen={open || hovered}
            toggleDrawer={toggleDrawer}
        />
    )
}

export default NavigationList





const transactions = {
    label: 'Transactions', link: '/transactions', icon: ImportExportRounded, subMenus: [
        { label: 'All', link: '/transactions/all', icon: ReceiptLongRounded, subMenus: [] },
        { label: 'Bank', link: '/transactions/bank', icon: AccountBalanceRounded, subMenus: [] },
        { label: 'Cash', link: '/transactions/cash', icon: PaymentsRounded, subMenus: [] },
    ]
}

const clients = {
    label: 'Clients', link: '/clients', icon: PeopleRounded, subMenus: [
        { label: 'Base', link: '/clients/base', icon: BusinessRounded, subMenus: [] },
        { label: 'Regional', link: '/clients/regional', icon: ContactsRounded, subMenus: [] },
    ]
}

const dashboard = { label: 'Dashboard', link: '/', icon: DashboardRounded, subMenus: [] }
const attendance = { label: 'Attendance', link: '/attendance', icon: PermContactCalendarRounded, subMenus: [] }
const farmers = { label: 'Farmers', link: '/farmers', icon: Diversity2Rounded, subMenus: [] }
const createFarmers = { label: 'Create Farmer', link: '/farmers/create', icon: Diversity2Rounded, subMenus: [] }
const crops = { label: 'Crop Management', link: '/crop-management', icon: GrassRounded, subMenus: [] }
const schemes = { label: 'Schemes', link: '/schemes', icon: DiscountRounded, subMenus: [] }
const providers = { label: 'Service Providers', link: '/providers', icon: AccountTreeRounded, subMenus: [] }
const clusters = { label: 'Clusters', link: '/clusters', icon: WorkspacesRounded, subMenus: [] }
const service = { label: 'Services', link: '/services', icon: MapRounded, subMenus: [] }
const users = { label: 'Users', link: '/users', icon: SupervisorAccountRounded, subMenus: [] }
const meetings = { label: 'Meetings', link: '/activity/meeting', icon: Groups3Rounded, subMenus: [] }
const ter = { label: 'Ter', link: '/ter-list', icon: DescriptionRounded, subMenus: [] }

const myAssociates = { label: 'Associates', link: '/my-associates', icon: Diversity1Rounded, subMenus: [] }
const cashDepositEntries = { label: 'Cash Deposit', link: '/cash-deposit-entries', icon: AssuredWorkloadRounded, subMenus: [] }


const hrReport = { label: 'Operator', link: '/reports/operator-report', icon: PeopleRounded, subMenus: [] }
const serviceReport = { label: 'Services', link: '/reports/service-report', icon: MapRounded, subMenus: [] }

const providersLink = {
    reports: [
        serviceReport,
        { label: 'Assets', link: '/reports/asset-report', icon: KeyboardCommandKeyRounded, subMenus: [] },
        hrReport,
        { label: 'Batteries', link: '/reports/battery-report', icon: BatteryFullRounded, subMenus: [] },
        { label: 'Vehicles', link: '/reports/vehicle-report', icon: TwoWheelerRounded, subMenus: [] },
    ],
    fleet: [
        { label: 'Operator', link: '/fleet-management/operators', icon: PeopleRounded, subMenus: [] },
        { label: 'Assets', link: '/fleet-management/assets', icon: KeyboardCommandKeyRounded, subMenus: [] },
        { label: 'Batteries', link: '/fleet-management/batteries', icon: BatteryFullRounded, subMenus: [] },
        { label: 'Vehicles', link: '/fleet-management/vehicles', icon: TwoWheelerRounded, subMenus: [] },
    ]
}

const d2fLinks = {
    reports: [
        { label: 'Farmers', link: '/reports/farmer-report', icon: Diversity2Rounded, subMenus: [] },
        { label: 'Farms', link: '/reports/farm-report', icon: HiveRounded, subMenus: [] },
        { label: 'Crops', link: '/reports/crop-report', icon: GrassRounded, subMenus: [] },
        { label: 'Regional Clients', link: '/reports/regional-clients-report', icon: ContactsRounded, subMenus: [] },
        { label: 'Schemes', link: '/reports/scheme-report', icon: DiscountRounded, subMenus: [] },
        { label: 'Meetings', link: '/reports/meeting-report', icon: GroupsRounded, subMenus: [] },
        { label: 'Asset Spray', link: '/reports/asset-spray-report', icon: DroneIcon, subMenus: [] },
    ],
    fleet: [
        { label: `DGE's`, link: '/fleet-management/dge', icon: RecordVoiceOverRounded, subMenus: [] },
    ]
}