import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { camelCaseWords, capitalizeWords, currencyFormatter } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';

export default function FarmerReport() {

    useDocumentTitle('Farmers')

    const theme = useTheme()
    const [farmers, setFarmers] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const fetchFarmers = useCallback(() => {
        setFetching(true)
        API.get('/download_farmer_report')
            .then((res) => {
                setFarmers(res.data?.data)
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [])

    useEffect(() => {
        fetchFarmers()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Farmer Report'} secondChild={
                <IconButton
                    onClick={fetchFarmers}
                    size='small'
                    sx={{
                        mr: 2,
                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                        border: `1px solid ${theme.palette.background.paper}`,
                    }}>
                    <Sync />
                </IconButton>
            } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                }}>

                <FarmersTable data={farmers} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const FarmersTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        {
            field: 'farmer_code', headerName: 'Farmer Code', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) =>
                <span style={{ textTransform: 'uppercase' }}>{params.value}</span>
        },
        {
            field: 'farmer_name', headerName: 'Farmer Name', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) =>
                <span>{camelCaseWords(params.value)}</span>
        },
        { field: 'farmer_mobile_no', headerName: 'Farmer Mobile No.', flex: 1, minWidth: 160 },
        { field: 'farmer_address', headerName: 'Farmer Address', flex: 1, minWidth: 160 },
        { field: 'farmer_district', headerName: 'District', flex: 1, minWidth: 160 },
        { field: 'farmer_sub_district', headerName: 'Subdistrict', flex: 1, minWidth: 160 },
        { field: 'farmer_village', headerName: 'Farmer Village / Town', flex: 1, minWidth: 160 },
        { field: 'farmer_state', headerName: 'Farmer State', flex: 1, minWidth: 160 },
        { field: 'farmer_pincode', headerName: 'Farmer Pin Code', flex: 1, minWidth: 100 },
        { field: 'farm_count', headerName: 'Farm Count', flex: 1, minWidth: 100 },
        { field: 'total_est_acerage', headerName: 'Total Est.Acreage', flex: 1, minWidth: 100 },
        { field: 'gender', headerName: 'Gender', flex: 1, minWidth: 100 },
        { field: 'dob', headerName: 'DOB', flex: 1, minWidth: 100 },
        { field: 'wedding_anniversary', headerName: 'Weeding anniversary ', flex: 1, minWidth: 160 },
        { field: 'education_level', headerName: 'Education Level ', flex: 1, minWidth: 100 },
        { field: 'profession', headerName: 'Profession ', flex: 1, minWidth: 100 },
        { field: 'income_level', headerName: 'Income Level ', flex: 1, minWidth: 100 },
        { field: 'influence_level', headerName: 'Influence Level', flex: 1, minWidth: 100 },
        { field: 'attitude', headerName: 'Attitude', flex: 1, minWidth: 160 },
        { field: 'lifestyle', headerName: 'Life Style', flex: 1, minWidth: 160 },
        { field: 'hobbies', headerName: 'Hobbies ', flex: 1, minWidth: 160 },
        { field: 'intrests', headerName: 'Interest', flex: 1, minWidth: 160 },
        { field: 'favourite_activity', headerName: 'Favourite Activity', flex: 1, minWidth: 160 },
        { field: 'communication', headerName: 'Communication ', flex: 1, minWidth: 100 },
        { field: 'email', headerName: 'Email ', flex: 1, minWidth: 150 },
        {
            field: 'social_media', headerName: 'Social Media', flex: 1, minWidth: 180,
            valueFormatter: ({ value }) => value ? (JSON.parse(value) ?? [])?.map((item: any, i: number) => ` ${item.name}: ${item.link}`) : ''
        },
        { field: 'rating', headerName: 'Rating ', flex: 1, minWidth: 100 },
        { field: 'feedback', headerName: 'Feedback', flex: 1, minWidth: 160 },

    ];

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter={smallScreen}
            disableColumnSelector={smallScreen}
            disableDensitySelector={smallScreen}
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


