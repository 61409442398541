import { useCallback, useState } from "react"
import API from "../api"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../store/store"
import { updateAuth } from "../slices/auth-slice"

export function useAuth() {

  const dispatch = useDispatch()
  const loggedUser = useSelector((state: RootState) => state.userAuth)

  const [fetchingUser, setFetchingUser] = useState(false)

  const fetchUser = useCallback(async () => {
    try {
      setFetchingUser(true)
      const response = await API.post("/get-user-detail", { user_id: loggedUser?.currentUser?.id })

      if (response.data?.status === "success") {
        dispatch(updateAuth({
          ...loggedUser,
          currentUser: {
            ...loggedUser?.currentUser,
            outstanding_balance: response.data?.data?.outstanding_balance,
          }
        }))
      } else {
        console.error(
          "Failed to fetch user:",
          response.data?.message || "Unknown error"
        )
      }
    } catch (error) {
      console.error("Error fetching user:", error)
    } finally {
      setFetchingUser(false)
    }
  }, [loggedUser])

  return {
    fetchUser,
    fetchingUser
  }
}
