import { Box, Button, ButtonBase, IconButton, Skeleton, Typography, alpha, useMediaQuery, useScrollTrigger, useTheme } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import { RootState } from '../../../store/store'
import { useSelector } from 'react-redux'
import { useCashDepositEntries } from '../../../hooks/useCashDepositEntries'
import TitleBar from '../../../components/container/TitleBar'
import NoDataView from '../../../components/noDataView'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import LoadingView from '../../../components/loading-view'
import { Sync } from '@mui/icons-material'
import { useAuth } from '../../../hooks/useAuth'


const DepositItem = React.lazy(() => import('./deposit-item'))
const CashDepositForm = React.lazy(() => import('./cash-deposit-form'))
const DepositDetails = React.lazy(() => import('./deposit-details'))
const OutstandingBalance = React.lazy(() => import('../../../components/OutstandingBalance'))


function CahsDepositList() {

    const theme = useTheme()
    const smallScreen = useMediaQuery('(max-width:900px)')

    const user = useSelector((state: RootState) => state?.userAuth?.currentUser)
    const allDeposits = useSelector((state: RootState) => state?.cashDeposits?.data)

    const [active, setActive] = useState(`Submitted`)

    const { fetchDepositEntries, fetchingDepositEntries } = useCashDepositEntries(`${user?.id}`)
    const { fetchUser } = useAuth()

    const [selectedDeposit, setSelectedDeposit] = useState<any | null>(null)
    const [openForm, setOpenForm] = useState(false)
    const closeForm = () => setOpenForm(false)

    const scrolled = useScrollTrigger({ disableHysteresis: true, threshold: 0, });

    const filterEntries = React.useMemo(() => {
        return allDeposits.filter((el: any) => {
            if (active === 'Submitted') return el.status === '1'
            if (active === 'Approved') return el.status === '2'
            if (active === 'Rejected') return el.status === '0'
            return el
        })
    }, [allDeposits, active])


    useEffect(() => {
        fetchUser()
    }, [])

    return (
        <>
            <Box
                sx={{
                    mb: 5,
                    position: 'relative',
                    pt: '60px',
                }}
            >
                <Box
                    sx={{
                        position: 'fixed',
                        top: '56px',
                        zIndex: 1,
                        background: theme.palette.background.paper,
                        pb: 1.5,
                        borderBottom: scrolled ? `1px solid #83838380` : 'none',
                        mt: { xs: 1, sm: 2 },
                        mx: { xs: 2, sm: 2 },
                        width: '-webkit-fill-available',
                    }}>
                    <TitleBar
                        backIcon
                        title={"All Deposits"}
                        secondChild={
                            <Box className={'flexCenter'} sx={{ gap: 2, }}>
                                <Suspense fallback={<Skeleton height={36} width={200} />}>
                                    <OutstandingBalance />
                                </Suspense>
                                <IconButton
                                    onClick={() => fetchDepositEntries()}
                                    size='small'
                                    sx={{
                                        mr: 2,
                                        background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                        boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                        border: `1px solid ${theme.palette.background.paper}`,
                                    }}>
                                    <Sync />
                                </IconButton>
                            </Box>
                        }
                    />

                    <Box
                        className={'flexStartCenter'}
                        sx={{
                            gap: 1,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            whiteSpace: 'nowrap',
                            scrollSnapType: 'x mandatory',
                            scrollSnapAlign: 'center',
                        }}>
                        {[`Submitted`, `Approved`, `Rejected`, `All`].map((item: string) => (
                            <ButtonBase
                                key={item}
                                onClick={() => setActive(item)}
                                sx={{
                                    background: active == item ? alpha(theme.palette.primary.main, 1) : alpha(theme.palette.text.disabled, 0.1),
                                    px: 1.5,
                                    py: 0.5,
                                    borderRadius: '50vh',
                                    color: active == item ? theme.palette.primary.contrastText : theme.palette.text.secondary,
                                }}>
                                {item}
                            </ButtonBase>
                        ))}
                    </Box>

                </Box>

                <Box
                    sx={{
                        pt: 6,
                        width: "100%",
                        display: 'grid',
                        justifyContent: 'stretch',
                        gap: 1,
                    }}
                >

                    {fetchingDepositEntries
                        ? <>
                            {
                                Array.from(Array(4)).map((_, i) => (
                                    <Box
                                        key={i}
                                        className={'animate__animated animate__fadeIn'}
                                        sx={{
                                            minHeight: '80px',
                                            background: alpha(theme.palette.text.disabled, 0.1),
                                            borderRadius: '18px',
                                        }} />
                                ))
                            }

                        </>
                        : filterEntries?.length > 0
                            ? filterEntries?.map((item: any, i: number) => (
                                <Suspense fallback={<LoadingView />}>
                                    <DepositItem key={i} details={item} handleClick={() => setSelectedDeposit(item)} />
                                </Suspense>
                            ))
                            : <NoDataView />
                    }
                </Box>

            </Box>



            {!!selectedDeposit &&
                <ThemeDialog
                    open={!!selectedDeposit}
                    size={smallScreen ? 'large' : 'small'}
                    title='Cash Deposit Details'
                    dialogBody={
                        <Suspense fallback={<LoadingView />}>
                            <DepositDetails detail={selectedDeposit} close={() => setSelectedDeposit(null)} />
                        </Suspense>
                    }
                />
            }


            {openForm &&
                <ThemeDialog
                    open={openForm}
                    size={smallScreen ? 'large' : 'small'}
                    title='Cash Deposit Form'
                    dialogBody={
                        <Suspense fallback={<LoadingView />}>
                            <CashDepositForm onClose={closeForm} />
                        </Suspense>
                    }
                />
            }

        </>

    )
}

export default CahsDepositList




