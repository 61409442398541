import React, { useCallback, useEffect, useState } from 'react'
import API from '../../api'
import PageContainer from '../../components/container/page-container'
import TitleBar from '../../components/container/TitleBar'
import { Box, Button, IconButton, Paper, alpha, useTheme } from '@mui/material'
import { useDispatch } from 'react-redux'
import ThemeDialog from '../../components/dialog-box/theme-dialog'
import { Edit, Sync } from '@mui/icons-material'
import { FormBlockTitle } from '../../components/title/FormBlockTitle'
import { downloadFile, titleCaseWords } from '../../utils/app-helper'
import { updateClusterDetail } from '../../slices/cluster-detail-slice'
import ClusterForm from './clusterForm'
import { FileDownloadIcon } from '../../components/icons'
import moment from 'moment'
import toast from 'react-hot-toast'



function Cluster() {

    const theme = useTheme()
    const dispatch = useDispatch()

    const [dialogOpen, setDialogOpen] = useState<'create' | 'edit' | null>(null)
    const [fetching, setFetching] = useState(true)
    const [clusters, setClusters] = useState<any[]>([])

    const closeDialog = () => {
        setDialogOpen(null)
        dispatch(updateClusterDetail({ selectedCluster: {} as any }))

    }

    const fetchClusters = useCallback(() => {
        setFetching(true)
        API.get('/fetch_all_clusters_report')
            .then((res) => {
                console.log(res.data)
                setClusters(res.data?.data ?? [])
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetching(false)
            })
    }, [])

    const downloadClusters = useCallback(() => {
        setFetching(true)
        API.get('/cluster-wise-report', {
            responseType: 'blob',
            maxBodyLength: Infinity,
        })
            .then((res) => {
                if (res.status === 200) {
                    downloadFile(res.data, `Cluster_Report-${moment().format('DD-MM-YYYY HH:mm:ss')}.xlsx`)
                } else {
                    toast.error("Failed to fetch attendance report")
                }
            })
            .catch((err) => console.error(err))
            .finally(() => {
                setFetching(false)
            })
    }, [])

    useEffect(() => {
        fetchClusters()
        dispatch(updateClusterDetail({ selectedCluster: {} as any }))
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Clusters'}
                secondChild={
                    <>
                        <IconButton
                            onClick={fetchClusters}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>

                        <IconButton
                            onClick={downloadClusters}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <FileDownloadIcon />
                        </IconButton>

                        <Button variant='contained'
                            className={'buttonGradient animate__animated animate__fadeInUp'}
                            onClick={() => setDialogOpen('create')}
                        >
                            Add New
                        </Button>
                    </>
                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },

                }}>
                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: `repeat( auto-fit, minmax(280px, 1fr))`,
                        gap: 3,
                        justifyItems: 'stretch',
                        '& .itemDetail': {
                            borderRadius: '18px',
                            background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.disabled, 0.1) : alpha(theme.palette.primary.main, 0.06),
                            p: '4px',
                            fontSize: '0.7rem',
                            fontWeight: 500,
                            display: 'flex',
                            flexFlow: 'column',
                            alignItems: 'stretch',
                            justifyContent: 'center',
                            fontFamily: theme.typography.h1.fontFamily,
                            color: theme.palette.primary.main,
                            textAlign: 'center',
                            '& span': {
                                flex: 1,
                                py: 0.5,
                                fontSize: '0.8rem',
                                background: theme.palette.background.default,
                                borderRadius: '16px',
                                color: theme.palette.mode == 'dark' ? theme.palette.text.primary : theme.palette.text.secondary,
                                fontWeight: 600,
                                fontFamily: theme.typography.body1.fontFamily,
                            },
                        },

                        '& .item': {
                            width: '100%',
                            borderRadius: '24px',
                            p: 1,
                            display: 'grid',
                            justifyContent: 'stretch',
                            background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.03)}, ${theme.palette.background.paper})`,
                            boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                            border: `2px solid ${theme.palette.background.paper}`,
                            '& .heading': {
                                fontSize: 'clamp(0.85rem, 3vw, 1rem)',
                                fontWeight: 500,
                                textAlign: 'center',
                                mb: 1,
                            },
                            '&:hover': {
                                boxShadow: `0 4px 18px -3px #83838380`,
                            },

                            '& ul': {
                                pl: '32px',
                                fontSize: '12px',
                                listStyle: 'disc',
                                height: '100px',
                                overflowY: 'scroll',
                                m: 0,
                                mr: 1,
                                textAlign: 'left'
                            },
                        },

                    }}>
                    {clusters?.map((cluster, index) => (
                        <Paper key={index} className={'item'}>
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    pr: 1,
                                    gap: 1.5,
                                    '& .MuiIconButton-root': {
                                        mb: 1,
                                        height: 24,
                                        width: 24,
                                        '& svg': {
                                            fontSize: '14px'
                                        },
                                    }
                                }}>
                                <FormBlockTitle icon={false} title={cluster?.cluster_name} />
                                <IconButton onClick={() => {
                                    setDialogOpen('edit')
                                    dispatch(updateClusterDetail({ selectedCluster: { ...cluster } as any }))

                                }}><Edit /></IconButton>
                            </Box>
                            <Box sx={{
                                display: 'grid',
                                gridTemplateColumns: `1fr 1fr`,
                                gap: 1,
                                mb: 1,
                            }}>
                                <Box className={'itemDetail'}>{titleCaseWords('Sub Districts')} <span>{(JSON.parse(cluster?.area_details) ?? [])?.length}</span></Box>
                                <Box className={'itemDetail'}>{titleCaseWords('Operators')} <span>{cluster?.number_of_operators}</span></Box>
                                <Box className={'itemDetail'}>{titleCaseWords('Assets')} <span>{cluster?.number_of_assets}</span></Box>
                                <Box className={'itemDetail'}>{titleCaseWords('Spray Capacity')} <span>{cluster?.total_sprayed_acreage}</span></Box>
                            </Box>

                            <Box className={'itemDetail'}>
                                {titleCaseWords('Sub Districts')}
                                <span>
                                    <ul>
                                        {(JSON.parse(cluster?.area_details) ?? [])?.map((area: any, i: number) => (
                                            <li key={area?.district}>
                                                <strong>{area?.sub_district}</strong> - {area?.district} ({area?.state})
                                            </li>
                                        ))}
                                    </ul>
                                </span>
                            </Box>
                        </Paper>
                    ))}
                </Box>

            </Box>

            {dialogOpen &&
                <ThemeDialog
                    open={dialogOpen != null}
                    title={dialogOpen == 'create' ? 'Create Cluster' : 'Update Cluster'}
                    dialogBody={<ClusterForm closeDialog={closeDialog} fetchClusters={fetchClusters} />}
                />
            }
        </PageContainer >
    )
}

export default Cluster