import { Box, IconButton, alpha, useMediaQuery, useTheme } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridToolbar } from '@mui/x-data-grid';
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeDataTable } from '../../components/theme-data-table-container';
import TableLoading from '../../components/theme-table-container/TableLoading';
import NoDataView from '../../components/noDataView';
import TitleBar from '../../components/container/TitleBar';
import PageContainer from '../../components/container/page-container';
import API from '../../api';
import { currencyFormatter, s3BucketUrl } from '../../utils/app-helper';
import { Sync } from '@mui/icons-material';
import { useDocumentTitle } from '../../hooks/useDocumnetTitle';
import moment, { Moment } from 'moment';
import toast from 'react-hot-toast';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { themeTextFieldSx } from '../../components/inputs/theme-text-field';


interface FilterOptionInterface {
    from: Moment | null,
    to: Moment | null,
}

export default function CashTransactionsReport() {

    useDocumentTitle('Cash Transactions')

    const theme = useTheme()
    const [transaction, setTransaction] = useState<any[]>([])
    const [fetching, setFetching] = useState<boolean>(true)

    const [filterOptions, setFilterOptions] = useState<FilterOptionInterface>({
        from: moment().subtract(1, 'month'),
        to: moment(),
    })


    const fetchTransactions = useCallback(() => {
        setFetching(true)
        API.post('/fetch-deposite-list', {
            from_date: filterOptions.from ? moment(filterOptions.from).format('YYYY-MM-DD') : '',
            to_date: filterOptions.to ? moment(filterOptions.to).format('YYYY-MM-DD') : '',
        })
            .then((res) => {
                if (res.data?.status == 'success') {
                    setTransaction(res.data?.data)
                } else {
                    toast.error(`Error\nFailed to fetch data`)
                }
            })
            .catch((err) => console.error(err))
            .finally(() => setFetching(false))
    }, [filterOptions])

    useEffect(() => {
        fetchTransactions()
    }, [])

    return (
        <PageContainer>
            <TitleBar title={'Cash Transactions'}
                secondChild={
                    <Box
                        className={'flexBottomCenter'}
                        sx={{
                            flex: '1 0 450px',
                            flexWrap: 'wrap',
                            gap: 1,
                            '& .formInput': {
                                minWidth: '110px',
                                maxWidth: '140px'
                            },
                        }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MobileDatePicker
                                disableFuture
                                label="From"
                                format='DD-MM-YYYY'
                                className='formInput'
                                value={filterOptions.from}
                                closeOnSelect
                                // onClose={(date: Moment) => setFilterOptions({ ...filterOptions, from: date })}
                                onChange={(newValue) => setFilterOptions({ ...filterOptions, from: newValue })}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        required: true,
                                        sx: themeTextFieldSx,
                                    }
                                }}
                            />
                            <MobileDatePicker
                                disableFuture
                                label="To"
                                format='DD-MM-YYYY'
                                className='formInput'
                                closeOnSelect
                                value={filterOptions.to}
                                onChange={(newValue) => setFilterOptions({ ...filterOptions, to: newValue })}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        required: true,
                                        sx: themeTextFieldSx,
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <IconButton
                            onClick={fetchTransactions}
                            size='small'
                            sx={{
                                mr: 2,
                                background: `linear-gradient(145deg, ${alpha(theme.palette.text.secondary, 0.1)}, ${theme.palette.background.paper})`,
                                boxShadow: `3px 3px 7px 0px ${alpha(theme.palette.text.secondary, 0.1)}, -3px -3px 8px ${theme.palette.background.paper}`,
                                border: `1px solid ${theme.palette.background.paper}`,
                            }}>
                            <Sync />
                        </IconButton>
                    </Box>

                } />

            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    '& .iconButton': {
                        height: '24px',
                        width: '24px',
                        borderRadius: '50vh',
                        // boxShadow: `0 0 12px -4px ${theme.palette.info.main} inset`,
                        display: 'grid',
                        placeItems: 'center',
                        color: theme.palette.info.main,
                        '& svg': {
                            height: '16px',
                            width: '16px',

                        },

                    },
                }}>

                <CashTransactionsTable data={transaction} fetching={fetching} />
            </Box>
        </PageContainer>
    )
}

const CashTransactionsTable = (props: any) => {

    const { data, fetching } = props
    const theme = useTheme()

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', flex: 1, minWidth: 80 },
        {
            field: 'order_id', headerName: 'Order Id', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'delivery_date', headerName: 'Delivery Date', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value : '-'
        },
        {
            field: 'invoice_amount', headerName: 'Invoice Amount', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => currencyFormatter(params.value)
        },
        {
            field: 'cash_amount', headerName: 'Amount', flex: 1, minWidth: 160,
            renderCell: (params: GridRenderCellParams) => currencyFormatter(params.value)
        },
        {
            field: 'created_at', headerName: 'Received Date', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? moment(value).format('DD-MM-YYYY HH:mm:ss') : '-'
        },
        {
            field: 'cash_deposited', headerName: 'Bank Deposit', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value == '1' ? 'Yes' : 'No' : '-'
        },
        {
            field: 'user_emp_id', headerName: 'Employee ID', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'user_name', headerName: 'Employee Name', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'user_role', headerName: 'Employee Role', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'deposite_reference_no', headerName: 'Deposit Reference No', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'deposite_id', headerName: 'Deposit Id', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'bank_deposite_emp_id', headerName: 'Bank Deposit By', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },
        {
            field: 'deposited_date', headerName: 'Deposit Date', flex: 1, minWidth: 160,
            valueFormatter: ({ value }) => value ? value?.toUpperCase() : '-'
        },

    ]

    const smallScreen = useMediaQuery('(max-width:767px)')

    return (
        <ThemeDataTable
            loading={fetching}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector={smallScreen}
            disableDensitySelector
            pageSizeOptions={[20, 50, 100]}
            density={'compact'}
            rows={data?.length > 0 ? data : []}
            columns={columns}
            slots={{
                toolbar: GridToolbar,
                loadingOverlay: TableLoading,
                noRowsOverlay: NoDataView,
            }}
            slotProps={{
                toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                },
            }}
            initialState={{
                filter: {
                    filterModel: {
                        items: [],
                        quickFilterExcludeHiddenColumns: true,
                    },
                },
            }}
        />
    )
}


