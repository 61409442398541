
import { BlurOnRounded, FolderSharedRounded, SnippetFolderRounded, SvgIconComponent } from '@mui/icons-material'
import { Box, Chip, Divider, IconProps, SvgIconProps, Typography, useTheme } from '@mui/material'

interface FormBlockTitleProps {
    title: string
    icon?: boolean
    color?: string
    padding?: boolean
}

function FormBlockTitle({ title, icon = false, color, padding = true }: FormBlockTitleProps) {

    const theme = useTheme()

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            mb: 1.5,
            color: color ?? theme.palette.primary.main,
            pl: padding ? 2 : 0,
        }}>
            {icon &&
                <FolderSharedRounded sx={{
                    fontSize: '14px'
                }} />
            }
            <Typography
                variant={'caption'}
                className={'animate__animated animate__fadeInUp'}
                sx={{
                    mb: 0,
                    lineHeight: '8px',
                    fontFamily: theme.typography.h1.fontFamily,
                    fontWeight: 500,
                }}>
                {title}
            </Typography>
            <Divider sx={{
                flex: 1,
                maxWidth: '70%',
                alignSelf: 'flex-end'
            }} />
        </Box>
    )
}

interface FormBlockTitle2Props {
    title: string
    icon?: boolean
    color?: string
}

function FormBlockTitle2({ title, icon = true, color }: FormBlockTitle2Props) {

    return (
        <Box sx={{
            width: '100%',
            mb: 1.5,
            '& .MuiChip-root': {
                p: '1px 8px 1px 4px',
                '& svg': {
                    fontSize: '14px',
                },
                '& .MuiChip-label': {
                    fontSize: '12px',
                },
            },
        }}>
            {icon
                ? <Chip size={'small'} label={title} icon={<SnippetFolderRounded color={'primary'} />} />
                : <Chip size={'small'} label={title} />
            }
        </Box>
    )
}

export { FormBlockTitle, FormBlockTitle2 }