import React from 'react'
import PageContainer from "../../components/container/page-container"
import { Box, alpha, useTheme } from "@mui/material"
import { useSelector } from 'react-redux'
import { User } from '../../models/user'
import { RootState } from '../../store/store'
import LoadingView from '../../components/loading-view'


const RmDashboard = React.lazy(() => import('./rm-dashboard'))
const CsoDashboard = React.lazy(() => import('./cso-dashboard'))
const ManagementDashboard = React.lazy(() => import('./management-dashboard'))
const AdminDashboard = React.lazy(() => import('./admin-dashboard'))



export default function Dashboard() {

    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const theme = useTheme()

    return (
        <PageContainer>
            <Box
                sx={{
                    flex: 1,
                    height: '100vh',
                    maxHeight: 'calc(100vh - 189px)',
                    transition: 'all 350ms ease-in-out',
                    display: 'flex',
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    flexWrap: 'wrap',
                    gap: 3,
                    '& .buttonGradient': {
                        backgroundImage: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                        outline: `0px solid ${theme.palette.primary.main}`,
                        outlineOffset: 0,
                        boxShadow: 0,
                        textTransform: 'none',
                        transition: 'all 100ms ease',
                        '&:hover': {
                            outline: `2px solid ${theme.palette.primary.main}`,
                            outlineOffset: '2px',
                            '&:active': {
                                transform: 'scale(0.98) !important'
                            }
                        },
                    },
                    '& .chartBox': {
                        borderRadius: '27px',
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                        background: theme.palette.mode == 'dark' ? alpha(theme.palette.text.disabled, 0.1) : alpha(theme.palette.primary.main, 0.06),
                        p: '3px',
                        '& .chartTitle': {
                            fontSize: '0.85rem',
                            fontWeight: 600,
                            color: theme.palette.primary.main,
                        },
                        '& svg': {
                            flex: 1,
                            maxWidth: '100%',
                            borderRadius: '24px',
                            '& > g': {
                                '&:not(:last-of-type)': {
                                    '& text': {
                                        color: theme.palette.text.secondary,
                                        fill: `${theme.palette.text.secondary} !important`,
                                    },
                                },
                            },
                            '& > rect': {
                                fill: theme.palette.background.paper,
                            },
                        },

                    },
                }}>
                {(currentUser?.role?.name == 'rm' && currentUser?.provider_id == '1') &&
                    <React.Suspense fallback={<LoadingView />}>
                        <RmDashboard />
                    </React.Suspense>
                }

                {(currentUser?.role?.name == 'cso' || currentUser?.role?.name == 'client') &&
                    <React.Suspense fallback={<LoadingView />}>
                        <CsoDashboard />
                    </React.Suspense>
                }

                {currentUser?.role?.name == 'management' &&
                    <React.Suspense fallback={<LoadingView />}>
                        <ManagementDashboard />
                    </React.Suspense>
                }

                {(currentUser?.role?.name == 'admin' || currentUser?.role?.name == 'super admin') &&
                    <React.Suspense fallback={<LoadingView />}>
                        <AdminDashboard />
                    </React.Suspense>
                }
            </Box>
        </PageContainer >
    )
}