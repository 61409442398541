import { alpha, Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { Suspense, useEffect, useState } from 'react'
import { FormBlockTitle } from '../../../components/title/FormBlockTitle'
import illustration from '../../../assets/images/bg/activty-illustration.png'
import ThemeDialog from '../../../components/dialog-box/theme-dialog'
import LoadingView from '../../../components/loading-view'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { useCashDepositEntries } from '../../../hooks/useCashDepositEntries'
import { useNavigate } from 'react-router'
import NoDataView from '../../../components/noDataView'
import { currencyFormatter } from '../../../utils/app-helper'
import { useAuth } from '../../../hooks/useAuth'


const DepositItem = React.lazy(() => import('./deposit-item'))
const DepositDetails = React.lazy(() => import('./deposit-details'))
const CashDepositForm = React.lazy(() => import('./cash-deposit-form'))


function CashDeposit() {

    const theme = useTheme()
    const navigate = useNavigate()
    const smallScreen = useMediaQuery('(max-width:900px)')


    const user = useSelector((state: RootState) => state?.userAuth?.currentUser)
    const allDeposits = useSelector((state: RootState) => state?.cashDeposits?.data)

    const { fetchDepositEntries, fetchingDepositEntries } = useCashDepositEntries(`${user?.id}`)
    const { fetchUser } = useAuth()

    const [openForm, setOpenForm] = useState(false)
    const [selectedDeposit, setSelectedDeposit] = useState<any | null>(null)

    const closeForm = () => setOpenForm(false)


    useEffect(() => {
        fetchDepositEntries()
        fetchUser()
    }, [])


    return (
        <>
            <Box
                sx={{
                    minHeight: '75vh',
                    display: 'flex',
                    flexFlow: 'column',
                    gap: 3,
                    maxWidth: '550px',
                    mx: 'auto',
                    // background: `url(${mainBg})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'bottom center',

                }}>
                <Box
                    className={'animate__animated animate__fadeIn'}
                    sx={{
                        borderRadius: '32px',
                        position: 'relative',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        boxShadow: `0 0 9px -3px ${alpha(theme.palette.primary.main, 0.6)}, 0 0 69px -3px ${alpha(theme.palette.primary.main, 0.5)} inset`,
                        p: 2,
                        minHeight: '140px',
                        border: `3px solid ${theme.palette.background.default}`,
                        background: `url(${illustration})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center right',
                        gap: 1,
                        '& .MuiTypography-root': {
                            color: theme.palette.text.secondary,
                            fontSize: theme.typography.pxToRem(16),
                            lineHeight: theme.typography.pxToRem(16),
                            '&.title': {
                                fontWeight: 600,
                                fontFamily: theme.typography.h1.fontFamily,
                                color: theme.palette.warning.main,
                                fontSize: theme.typography.pxToRem(24),
                                lineHeight: theme.typography.pxToRem(24),
                            },
                        },
                    }}>
                    <Box>
                        <Typography className={'animate__animated animate__fadeInUp'}>Outstanding Balance</Typography>
                        <Typography className={'title animate__animated animate__fadeInUp'}>{user.outstanding_balance ? currencyFormatter(+user.outstanding_balance) : ''}</Typography>
                        <Button variant='contained' color={'primary'} sx={{ mt: 2, }} onClick={() => setOpenForm(true)}>Deposit</Button>
                    </Box>
                </Box>

                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            justifyContent: 'space-between',
                            columnGap: 3,
                            mb: 2,
                            '& .MuiTypography-root': {
                                fontSize: '16px',
                                fontWeight: 500,
                            },

                        }}>
                        <FormBlockTitle title='Recent Deposits' icon={false} />
                        <Box component={'span'}
                            onClick={() => navigate('/cash-deposit/list')}
                            sx={{
                                background: alpha(theme.palette.text.disabled, 0.1),
                                borderRadius: "8px",
                                p: "1px 8px",
                                fontSize: "12px",
                                whiteSpace: 'nowrap',
                                cursor: 'pointer'
                            }}
                        >
                            View All
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            whiteSpace: 'nowrap',
                            scrollSnapType: 'x mandatory',
                            scrollSnapAlign: 'center',
                        }}>
                        <Box
                            sx={{
                                pt: 6,
                                width: "100%",
                                display: 'grid',
                                justifyContent: 'stretch',
                                gap: 1,
                            }}
                        >

                            {fetchingDepositEntries
                                ? <>
                                    {
                                        Array.from(Array(4)).map((_, i) => (
                                            <Box
                                                key={i}
                                                className={'animate__animated animate__fadeIn'}
                                                sx={{
                                                    minHeight: '80px',
                                                    background: alpha(theme.palette.text.disabled, 0.1),
                                                    borderRadius: '18px',
                                                }} />
                                        ))
                                    }

                                </>
                                : allDeposits?.length > 0
                                    ? allDeposits?.slice(0, 5)?.map((item: any, i: number) => (
                                        <Suspense fallback={<LoadingView />}>
                                            <DepositItem key={i} details={item} handleClick={() => setSelectedDeposit(item)} />
                                        </Suspense>
                                    ))
                                    : <NoDataView />
                            }
                        </Box>
                    </Box>
                </Box>
            </Box>


            {openForm &&
                <ThemeDialog
                    open={openForm}
                    size={smallScreen ? 'large' : 'small'}
                    title='Cash Deposit Form'
                    dialogBody={
                        <Suspense fallback={<LoadingView />}>
                            <CashDepositForm onClose={closeForm} />
                        </Suspense>
                    }
                />
            }

            {!!selectedDeposit &&
                <ThemeDialog
                    open={!!selectedDeposit}
                    size={smallScreen ? 'large' : 'small'}
                    title='Cash Deposit Details'
                    dialogBody={
                        <Suspense fallback={<LoadingView />}>
                            <DepositDetails detail={selectedDeposit} close={() => setSelectedDeposit(null)} />
                        </Suspense>
                    }
                />
            }

        </>
    )
}

export default CashDeposit


